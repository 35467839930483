import t from '@jetshop/intl';
import React from 'react';
import {styled} from 'linaria/react';
import {theme} from './Theme';
import {TrackPageLoad} from './DataLayer/DataLayer';
import {TrendLink} from "./ui/Button";

const ErrorContainer = styled('div')`
  padding: 2rem;
  align-items: flex-start;
  line-height: 1.75;
  max-width: 100%;
  width: 80ch;
  margin: 0 auto;

  header {

    p {
      font-weight: ${theme.fontWeights.regular};
      font-size: 18px;
    }
  }

  h1 {
    font-size: 40px;
    margin-bottom: 1rem;
  }
  
  h2 {
    margin-top: 2.5rem;
  }

  > * + * {
    margin-top: 1em;
    margin-bottom: 0;
    padding: 0;
  }

  p {
    text-align: left;
  }
  
  ul {
    list-style: disc;
    padding-left: 1rem;
    
    li {
      line-height: 1.4;
      
      + li {
        margin-top: 0.5rem;
      }
    }
  }
   
  
`;


const LinkButton = styled(TrendLink)`
  max-width: 20rem;
  margin-top: 3rem;

`;



const NotFoundPage = () => {
  return (
    <ErrorContainer>

      <TrackPageLoad pageType={'404-sida (ej hittad)'}/>
      <header>
        <h1>{t('Whoops')}</h1>
        <p>
          {t(`You have attempted to download a page that does not exist on Jetshop's
          server.`)}
        </p>
      </header>
      <h2>{t('This could be due to the following:')}</h2>
      <ul>
        <li>
          {t(
            'The address provided is incorrect. Please check the spelling and try again.'
          )}
        </li>
        <li>
          {t(
            'You are trying to use an old address that no longer exists or that may have changed.'
          )}
        </li>
        <li>
          {t(
            'You may have used an old bookmark. If you are sure that you have used the correct address to the site, the problem may be caused by a temporary service disruption. Please try again later.'
          )}
        </li>
      </ul>
      {/*<p>*/}
      {/*  {t(*/}
      {/*    'NOTE! If you were directed here via a link we are grateful if you inform us as to which link it was so that we can resolve the problem.'*/}
      {/*  )}*/}
      {/*</p>*/}
      <LinkButton to="/">
        {t('HOMEPAGE')}
      </LinkButton>
    </ErrorContainer>
  );

}

export default NotFoundPage;
